import React from "react";
import Layout from "../../Layout";
import "./style.scss";
import data from "./data";
import Seo from "../../seo";
export default function VisualArts() {
  let dataCol = {
    0: [],
    1: [],
    2: [],
  };

  data.forEach((item, i) => {
    dataCol[`${i % 3}`].push(item);
  });

  return (
    <Layout>
      <Seo
        title="Visual Arts | Chinmaya Vidyalaya"
        description=""
        img="https://chinmayavvdelhi.ac.in/images/Blackele/Chinmaya_link_preview.jpg"
        keywords=""
      />
      <div className="visualarts">
        <h1 className="heading">Visual Arts</h1>

        <p className="visualarts__text">
          <strong>
            Art is not what you see, but what you make others see.
          </strong>
          <br />
          <br />
          Visual art is a diverse and captivating realm of artistic expression,
          with painting being prominent and cherished form within this domain.
          It enables artists to communicate their thoughts, emotions, explore
          their creativity and delve into a world of limitless possibilities
          through the vibrant language of colours and brush strokes. With each
          stroke of the brush, they can experiment with various techniques,
          textures and styles giving life to their vision on the canvas or
          paper.
          <br />
          <br />
          It is not enough to just give our children the skills they need to be
          successful in life, it is equally important that these skills are
          developed holistically. Our school Chinmaya Vidyalaya recognizes the
          importance of promoting holistic education and encourages students to
          participate in various co- curricular activities to develop their
          creativity and foster their problem-solving skills. It helps students
          to explore their creativity and also foster a life of learning and a
          sense of accomplishment.
        </p>

        <div className="visualarts__images">
          <div className="visualarts__images__row">
            <div className="visualarts__images__col">
              {dataCol["0"].map((item) => (
                <div className="visualarts__images__card">
                  <img alt="Visual art" loading="lazy" src={item.link}></img>
                  <div className="visualarts__images__card__title">
                    {item.name} - CLASS {item.class}
                  </div>
                </div>
              ))}
            </div>
            <div className="visualarts__images__col">
              {dataCol["1"].map((item) => (
                <div className="visualarts__images__card">
                  <img alt="Visual art" loading="lazy" src={item.link}></img>
                  <div className="visualarts__images__card__title">
                    {item.name} - CLASS {item.class}
                  </div>
                </div>
              ))}
            </div>
            <div className="visualarts__images__col">
              {dataCol["2"].map((item) => (
                <div className="visualarts__images__card">
                  <img alt="Visual art" loading="lazy" src={item.link}></img>
                  <div className="visualarts__images__card__title">
                    {item.name} - CLASS {item.class}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
