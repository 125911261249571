const data = [
  {
    class: "XI",
    name: "ADARSH",
    link: "https://chinmayavvdelhi.ac.in/assets/images/visual-arts/2024/ADARSH-XI.jpg",
  },
  {
    class: "XII",
    name: "AMAN",
    link: "https://chinmayavvdelhi.ac.in/assets/images/visual-arts/2024/AMAN-XII.jpg",
  },
  {
    class: "XI",
    name: "CHANDER",
    link: "https://chinmayavvdelhi.ac.in/assets/images/visual-arts/2024/CHANDER-XI.jpg",
  },
  {
    class: "XII",
    name: "CHARVI RUDRA",
    link: "https://chinmayavvdelhi.ac.in/assets/images/visual-arts/2024/CHARVI-RUDRA-XII.jpg",
  },
  {
    class: "XII",
    name: "HIMANSHU",
    link: "https://chinmayavvdelhi.ac.in/assets/images/visual-arts/2024/HIMANSHU-XII.jpg",
  },
  {
    class: "IX",
    name: "KAUSHAL BHARDWAJ",
    link: "https://chinmayavvdelhi.ac.in/assets/images/visual-arts/2024/KAUSHAL-BHARDWAJ-IX-(2).jpg",
  },
  {
    class: "IX",
    name: "KAUSHAL BHARDWAJ",
    link: "https://chinmayavvdelhi.ac.in/assets/images/visual-arts/2024/KAUSHAL-BHARDWAJ-IX.jpg",
  },
  {
    class: "XII",
    name: "KUNAL",
    link: "https://chinmayavvdelhi.ac.in/assets/images/visual-arts/2024/KUNAL-XII-(2).jpg",
  },
  {
    class: "XII",
    name: "KUNAL",
    link: "https://chinmayavvdelhi.ac.in/assets/images/visual-arts/2024/KUNAL-XII.jpg",
  },
  {
    class: "XII",
    name: "PRANAY",
    link: "https://chinmayavvdelhi.ac.in/assets/images/visual-arts/2024/PRANAY-XII.jpg",
  },
  {
    class: "XI",
    name: "PRIYA",
    link: "https://chinmayavvdelhi.ac.in/assets/images/visual-arts/2024/PRIYA-XI-(2).jpg",
  },
  {
    class: "XI",
    name: "PRIYA",
    link: "https://chinmayavvdelhi.ac.in/assets/images/visual-arts/2024/PRIYA-XI.jpg",
  },
  {
    class: "XI",
    name: "REEMA",
    link: "https://chinmayavvdelhi.ac.in/assets/images/visual-arts/2024/REEMA-XI.jpg",
  },
  {
    class: "XI",
    name: "REHA",
    link: "https://chinmayavvdelhi.ac.in/assets/images/visual-arts/2024/REHA-XI.jpg",
  },
  {
    class: "IV",
    name: "R SADIKA",
    link: "https://chinmayavvdelhi.ac.in/assets/images/visual-arts/2024/S-SADIKA-IV.jpg",
  },
  {
    class: "XII",
    name: "SUMIT",
    link: "https://chinmayavvdelhi.ac.in/assets/images/visual-arts/2024/SUMIT-XII.jpg",
  },
  {
    class: "XII",
    name: "TANYA SINGH",
    link: "https://chinmayavvdelhi.ac.in/assets/images/visual-arts/2024/TANYA-SINGH-XII.jpg",
  },
  {
    class: "XII",
    name: "ZYAN",
    link: "https://chinmayavvdelhi.ac.in/assets/images/visual-arts/2024/ZYAN-XII.jpg",
  },
];

export default data;
